<template>
  <div class="body-bg">
    
    <h2 class="timeline-title">与辛合的恋爱时间线</h2>
    <div class="timeline">
      <div class="timeline-line"></div>
      <div v-for="(item, index) in timelineData" :key="index" class="timeline-item">
        <div class="timeline-dot"></div>
        <div class="timeline-content" :class="index % 2 === 0 ? 'left-content' : 'right-content'">
          <div class="date">{{ item.date }}</div>
          <div class="event" @click="showEventDescription(item.description)">{{ item.event }}</div>
        </div>
      </div>
    </div>
    <div class="time-duration">至此，</div>
    
    <div class="time-duration">
    咱们已经认识了{{ knownDuration }}，
    </div>
    <div class="relationship-duration">
    已经在一起了{{ relationshipDuration }}。
  </div>
  <div class="relationship-duration">
    希望这条时间线可以被拉到无限长，
  </div> 
  <div class="relationship-duration">
    也希望上面的时间永远不会停止... 
  </div> 
   <div class="relationship-duration">
    一起创造更多的第一次！
  </div>

    <div class="modal" v-if="showModal">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <p>{{ selectedEventDescription }}</p>
    </div>
  </div>
  </div>

</template>


<script>
export default {
  data() {
    return {
      timelineData: [
        {"date":"2022-7-18","event":"初次相识","description": "经人介绍，咱们互相加了微信，从此命运的齿轮开始转动..."},
        {"date":"2022-8-30","event":"第一次见面","description": "这一天，咱们在印象城的海底捞见面啦～这是咱们的第一次见面，你神仙女孩的形象这时候就在俺心中建立起来啦！"},
        {"date":"2022-8-30","event":"第一次一起吃饭","description": "我傻乎乎滴点了两个番茄锅底..."},
        {"date":"2022-8-30","event":"第一次骑同一辆电动车","description": "骑上你心爱滴小Polly，它永远不会堵车...就像我之前说的那样，这是俺第一次坐女孩子的电动车后座"},
        {"date":"2022-9-17","event":"第一次互通电话","description": "虽然只打了半个小时电话，但俺那晚差点儿高兴滴睡不着觉"},
        {"date":"2023-5-29","event":"时隔将近一年的第二次见面...","description": "你遵守约定，请我吃第二顿饭，但因为我自己的原因，吃得很少...很抱歉那天让你生气啦！"},
        {"date":"2023-5-29","event":"第一次送你礼物","description": "在鸡鸣寺求来的十八籽，希望你未来能够一切顺利，当时还怕你会不收，提前想了很多话术让你收下，结果没想到你很高兴地就收下了！那时候俺真的很开心！后来在你的剪的跟舍友的视频里，无意中看到了你带着它的一张照片，俺高兴了很久很久！"},
        {"date":"2023-7-18","event":"相识一周年","description": "23年7月18号是我们认识一年的日子，上个时间点距离这一天，我们几乎已经将近一个多月没有联系了，我以为我们已经没有可能了，给你发了一堆奇怪的话（道别？），但还是很想跟你继续做朋友"},
        {"date":"2023-9-6","event":"断线重连...","description": "心里还是特别放不下你，这天看到你发了一条朋友圈，说要听歌带走一些烦恼，没忍住就主动找你问了问近况...没想到这竟然成为了我们关系的转折点！"},
        {"date":"2023-9-16","event":"第一次通话时间超过1小时...","description":"因为我的错，让你不开心了，当天跟你通了一小时电话，期间多谢你主动说开了我们的关系，后来你说咱们没可能了，我难受许久许久...一时间也不知道要说什么了，一小时的电话有将近半小时都是相顾无言...但还是不舍得挂断你的电话，电话挂掉后，因为说开了嘛，我就把22年9月写的4000字小作文发给了你..."},
        {"date":"2023-9-23","event":"约定第三次见面...","description":"你答应我可以去青州找你玩啦！那天我简直高兴到飞起！"},
        {"date":"2023-10-3","event":"青州会师！我们在一起啦！","description":"这一天的事儿，对你对我都可以用魔幻来形容！虽然过程有些坎坷，但是我们顺其自然的在一起啦！"},
        {"date":"2023-10-3","event":"第一次牵你的手！小手冰凉哦～","description":"我们过马路时，绿灯突然亮了，我示意你快走，想挡在前面把你护在身后，没想到这时候你主动上来拉住我的手！当时俺特别特别激动，这是俺第一次拉女孩子的手，估计那时候心跳直接上到180了吧哈哈哈哈哈哈，就这样我们紧紧拉着手，直到最后上车才舍得松开，这段记忆我这辈子都不会忘记！你的小手有点凉哦～冬天快到了记得保暖！"},
        {"date":"2023-10-3","event":"第一次接你下班，送你回家","description":"这是俺第一次送回家，希望未来都能接你下班，带你回家～"},
        {"date":"2023-10-3","event":"舍不得！","description":"回到酒店，想着第二天要回家，心里特难受，就想跟你多待一天，后来决定留下啦！"},
        {"date":"2023-10-4","event":"第一次送你花花","description":"在一起之后，觉得还欠你一个正式的表白，所以等你下班的时候我就赶紧买了花，就是这个网站的背景图啦！不知道为啥，买来放了一会儿这花就有点蔫了，哈哈后来给你的时候有点不好意思，下一次见面俺一定亲自去花店挑好送给你！(真的不是在画大饼～[手动狗头])"},
        {"date":"2023-10-4","event":"第一次一起看电影","description":"这天咱们在电影院一起看了坚如磐石，期间你出去了20多分钟，但是对剧情的理解还是要远高于看完全场的我，真是自愧不如啦！但是！这不是重点！重点是希望我们的感情也能像电影的名字一样，坚如磐石！"},
        {"date":"2023-10-4","event":"第一次拥抱","description":"看完电影送你回家，送你的时候特别舍不得离开你，虽然把车开到了你校区单元门口，但此时此刻你也一样，想跟我再多说说话，那时你把头靠在我的肩膀上，我心想未来一定要好好保护好你，但是现实就是那么的残酷，我必须要走了，只能盼望下一次的日期难以确定的见面了，临送你上楼，咱们第一次拥抱在一起，虽然时间很短，但是我多么希望能够多跟你拥抱一会呀！"},
        {"date":"2023-10-9","event":"第一次视频聊天","description":"第一次开视频聊天，俺有点羞涩哈哈哈，所以一直开着前置镜头在俺的后花园——玄武湖里逛..."},
        {"date":"2023-10-10","event":"第一次视频学习","description":"第一次通过视频共同学习，这时候我感觉有你在，学习效率超高！"},
        {"date":"2023-10-18","event":"手足无措","description":"这天感觉你状态有点不太对，跟你聊了几句，得知你不舒服，我有点手足无措，就赶紧让你去休息了，之后我狂翻小红书，看看这时候应该买点啥能帮上忙，然后在京东上买了姜水和暖宝宝，后来发觉我可能有些反应过度了，但是这时候我确实不知道该怎么做了，真想闪现到青州陪你，但也只能通过这种形式关心你了，感觉可能有点让你不舒服吧，以后，我买东西一定要先征求你的同意！绝不乱花钱！"},
        {"date":"2023-10-20","event":"你回家啦！","description":"前一天晚上，我加了会儿班，下班跟你聊了几句，感觉你可能还是不舒服，后面就没再找你，虽然很想跟你打个电话，但是忍住了，想着给你发一段话过去，让你早点睡觉明天把快递领了，正编辑着呢，你视频电话打过来了，可能这就是默契吧！你要回家了，可能两天都没法给我打电话，就想着前一天提前给我打一个，我那时候特别感动，虽然聊的时间不是很长，你说有点不习惯打电话，我心里咯噔一下，确实，最近的我们像是进入了平淡期，话题变少了，总是相顾无言，但这应该是必经的阶段吧，陪伴是最长情的告白！但是最后还是很开心地道别啦，希望广州的事儿能赶紧办完，早点回家跟你见面..."},
        {"date":"2023-10-21","event":"这个网站诞生啦！","description":"其实很早之前我就注册了一个xinhe.love的域名（可以简单理解成网址啦），想着以后能派上用场，没想到真给用上了！20号你回家了，这天咱们没怎么聊，下午问了一句你上车没，到了快7点都没收到回复，心里就有点担心，然后赶紧就再问了一句确认了下你有没有安全到家，其实俺特别理解，你上班很忙很累，回家是仅有的能放松的时间和陪伴父母的时间，所以这两天俺就不找你聊啦，好好休息休息，陪一下父母和妹妹吧！但碍不住心里总是想你，于是乎我就决定——化思念为动力（想你的时候就写写代码哈哈哈哈哈），把这个网站搞起来吧（嘿嘿以前就想着要不要做一个记录咱们恋爱经历的网站，毕竟这可是俺的专业领域！），然后就敲了一整晚的代码，把这个网站做了出来！顺手把咱们的一些重要时间点也记录下来啦(嘿嘿有些时间点的细节可能不完善，后面俺再补充补充)，目前是21号03:35，这条时间点写完我就要睡觉啦！目前还有些东西需要完善，希望周天晚上能给你看到这个网站，希望你能多给这个网站提提意见（毕竟你的审美要比我这个直男审美好得多哈哈），咱们未来一起构建起这个网站。好啦，时间不早了，睡觉去咯！"}

        // 添加更多示例数据
      ],
      showModal: false, // 控制弹窗的显示/隐藏
      selectedEventDescription: "", // 存储选中事件的描述
      knownStartDate: new Date('2022-07-18'), // 初次相识的日期
      relationshipStartDate: new Date('2023-10-03'), // 恋爱开始的日期
      knownDuration: "", // 已经认识的时间
      relationshipDuration: "", // 恋爱时长
    };
  },computed: {
  
  },  
  created() {
    // 初始化时更新时间
    this.updateDurations();
    // 每秒钟更新一次时间
    setInterval(this.updateDurations, 1000);
  },
  methods: {
  showEventDescription(description) {
    this.selectedEventDescription = description;
    this.showModal = true;
  },
  closeModal() {
    this.showModal = false;
  },
  updateDurations() {
      const currentDate = new Date(); // 当前日期
      const knownTimeDifference = currentDate - this.knownStartDate;
      const relationshipTimeDifference = currentDate - this.relationshipStartDate;
      this.knownDuration = this.calculateDuration(knownTimeDifference);
      this.relationshipDuration = this.calculateDuration(relationshipTimeDifference);
  },
  calculateDuration(timeDifference) {
      const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));
      const remainingTime = timeDifference % (1000 * 60 * 60 * 24 * 365);

      const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
      const remainingHours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const remainingMinutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
      const remainingSeconds = Math.floor((remainingTime % (1000 * 60) / 1000));

      const yearsText = years > 0 ? `${years}年` : '';
      const daysText = days > 0 ? `${days}天` : '';
      const hoursText = `${remainingHours}小时`;
      const minutesText = `${remainingMinutes}分钟`;
      const secondsText = `${remainingSeconds}秒`;
      return `${yearsText}${daysText}${hoursText}${minutesText}${secondsText}`;
    },
  
},

};
</script>

<style scoped>
.timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 90%; /* 控制时间轴的宽度 */
  margin: 0 auto; /* 居中显示 */
}

.timeline-line {
  width: 2px; /* 更细的时间轴线 */
  height: 100%;
  background: #f39c12; /* 橙色时间轴线 */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.timeline-item {
  display: flex;
  align-items: center;
  margin-top: 20px;
  position: relative;
}


.timeline-dot {
  width: 20px;
  height: 20px;
  background: url('heart-icon.png') no-repeat center center;
  background-size: contain;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3; /* 确保点在内容的上层 */
}
.timeline-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 25;
}


.date {
  font-size: 18px; /* 增加字体大小 */
  font-weight: bold;
  color: #fff; /* 白色文字颜色 */
  background-color: #3498db; /* 蓝色背景颜色 */
  padding: 8px 12px; /* 内边距 */
  border-radius: 10px; /* 圆角边框 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 阴影效果 */
  display: inline-block; /* 内容块元素 */
  margin-bottom: 8px; /* 增加下边距 */
}

.left-content {
  align-self: flex-start; /* 左侧内容靠左 */
  margin-right: 200px; /* 增加右侧间距 */
  z-index: 3;
  /* padding-right: 70px; */
}

.right-content {
  align-self: flex-end; /* 右侧内容靠右 */
  margin-left: 200px; /* 增加左侧间距 */
  z-index: 3;

  /* padding-right:30px; */
}

.event {
  margin-top: 10px; /* 增加事件文字与时间点之间的垂直间距 */
}

.timeline-title {
  font-size: 32px; /* 设置字体大小 */
  font-weight: bold; /* 加粗文本 */
  color: #e74c3c; /* 设置文本颜色 */
  text-align: center; /* 文本居中对齐 */
  padding: 20px; /* 增加内边距 */
}

.body-bg {
  position: relative;
  background-image: url('background-image.jpg'); /* 设置背景图片的URL */
  background-size: cover; /* 背景图片填充整个屏幕 */
  background-position: center; /* 图片居中对齐 */
  background-repeat: no-repeat; /* 防止背景图片重复 */
  background-attachment: fixed; /* 固定背景图片 */
  color: #333; /* 设置文本颜色 */
  font-family: Arial, sans-serif; /* 设置字体 */
  margin: 0; /* 去除页面边距 */
  padding: 0; /* 去除页面内边距 */
  overflow-x: hidden; /* 防止水平滚动条出现 */
  z-index: 0;
}

.body-bg::before {
  content: "";
  background: rgba(255, 255, 255, 0.75); /* 白色蒙版的颜色和透明度 */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}


.modal {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 22px;
  border: 2px solid #888;
  width: 80%;
  max-width: 600px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: left;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover {
  color: #000;
}
.time-duration,.relationship-duration {
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
  color: #6f7aac; /* 你喜欢的颜色 */
}

</style>
