<template>
  <div id="app">
    <TimeLine />
  </div>
</template>

<script>
import TimeLine from './components/TimeLine.vue';

export default {
  components: {
    TimeLine,
  },
};
</script>

<style>
#app {
  margin: 0px;
}
</style>
